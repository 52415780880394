<template>
  <div class="body-container" v-if="!this.isLoading">
    <div class="d-flex flex-column text-center mt-2">
      <div class="col-3 profile-header">My Profile</div>
      <img class="main-banner" src="../assets/game/main-banner.png">
      <div class="d-flex flex-row justify-content-center mt-1">
        <div class="d-flex flex-column align-items-center justify-content-center user-stats-container m-1">
          <div class="stats-header-text col-10">SEASON POINTS</div>
          <div class="stats-points-text">{{ userLandingStats.userStats.totalFreePlaySeasonPoints }}</div>
        </div>
        <div class="d-flex flex-column align-items-center justify-content-center user-stats-container m-1">
          <div class="stats-header-text col-10">ROUNDS ENTERED</div>
          <div class="stats-points-text">{{ userLandingStats.userStats.roundsPreviouslyEntered }}</div>
        </div>
        <div class="d-flex flex-column align-items-center justify-content-center user-stats-container m-1">
          <div class="stats-header-text col-10">PRIZES WON</div>
          <div class="stats-points-text">{{ userLandingStats.userStats.prizesWon }}</div>
        </div>
      </div>
      <div class="mt-2 top-predictors-container">
        <div class="col-5 top-predictors-text">
          TOP PREDICTORS
        </div>
        <div class="d-flex flex-row justify-content-between p-1 text-center pt-2">
          <div class="flex-column align-items-center text-center col-2" v-for="(predictor) in userLandingStats.topFreePlayPredictors" :key="predictor.id">
            <img class="predictor-img" :src="require(`../assets/game/user-icon.png`)"/>
            <p class="pt-1 top-predictor-username">{{ predictor.username }}</p>
            <p class="top-predictor-ranking">{{ predictor.rank }}</p>
          </div>
        </div>
      </div>
      <div @click="routeToPage('how-to-play')" class="d-flex flex-column justify-content-center mt-2 how-to-play-container">How To Play</div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';

export default {
  name: 'LandingPage',
  data() {
    return {}
  },
  computed: {
    ...mapState(['isLoading', 'userLandingStats', 'user'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setUserSubscriptionStatus']),
    setUserSubscriptionService() {
      this.setUserSubscriptionStatus(this.user.status)
    },
    routeToPage(pageRoute) {
      this.$router.push(`${pageRoute}`)
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    this.setUserSubscriptionService();
    this.setIsLoading(false);
  },
  mounted() {
    let webViewScript = document.createElement('script')
    webViewScript.setAttribute('src', 'https://appx/web-view.min.js')
    document.head.appendChild(webViewScript)
  },
}
</script>

<style scoped>
.body-container {
  background-color: #e8e8e8;
  overflow-y: hidden;
}

.profile-header {
  font-weight: 600;
}

.main-banner {
  margin-top: -12px;
}

.user-stats-container {
  background-color: #EF151C;
  color: #FFFFFF;
  height: 110px;
  border-radius: 10px;
  width: 33%;
}

.stats-header-text {
  height: 20px;
  font-weight: 600;
  font-size: 12px;
}

.stats-points-text {
  position: relative;
  top: 15px;
  font-size: 35px;
  font-weight: 700;
}

.predictor-img {
  height: 38px;
}

.top-predictor-username {
  font-size: 11px;
  height: 25%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 600;
}

.top-predictor-ranking {
  margin-top: -12px;
  font-size: 9px;
  font-weight: 600;
}

.top-predictors-container {
  color: #FFFFFF;
  background-color: #464646;
  border-radius: 10px;
  height: 130px;
}

.top-predictors-text {
  font-weight: bold;
  margin-left: 10px;
  margin-top: 10px;
}

.how-to-play-container {
  background-color: #0c8a0c;
  height: 40px;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: 600;
}

@media screen and (max-width: 370px) {
  .stats-header-text {
    font-size: 11.5px;
  }
}

</style>
